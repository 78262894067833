import Component from 'components/component'
import { formatDuration } from 'helpers/date'
import { DI } from 'modules'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import { copy } from 'utils/buffer'
import { PER_PAGE } from '../../pages/business/user/my-lists/view/config'
import DBLItem from './item-views/dbl-item'
import DBSItem from './item-views/dbs-item'
import GCItem from './item-views/gc-item'
import LCItem from './item-views/lc-item'

class ListItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    link: PropTypes.string.isRequired,
    time: PropTypes.any,
    view: PropTypes.oneOf(['auto', 'ae', 'lae', 'dbl', 'dbs']),

    isSpherical: PropTypes.bool,
    isFree: PropTypes.bool,
    isPool: PropTypes.bool,
    isRemove: PropTypes.bool,
    isOnLive: PropTypes.bool,
    canCopyLink: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired,
      })
    ),
    removeButton: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired,
      })
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    addToListButton: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        items: PropTypes.array,
        listsWithVideo: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        onOpen: PropTypes.func.isRequired,
      })
    ),

    region: PropTypes.string,
    country: PropTypes.string,
    location: PropTypes.string,

    isLive: PropTypes.bool,
    status: PropTypes.string,
    tbc: PropTypes.bool,
    preview: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.string),
    tagLink: PropTypes.func,
  }

  static defaultProps = {
    canCopyLink: true,
    isSpherical: false,
    isFree: false,
    isRemove: false,
    isPool: false,
    isOnLive: false,
    tags: [],
    buttons: [],
    removeButton: [],
    slides: [],
    view: 'auto',
    addToListButton: [],
  }

  state = {
    hover: false,
  }

  get removeButton() {
    if (this.props.isRemove) {
      const list_id = this.props.match.params.id
      const data = this.props
      return this.props.removeButton.concat({
        text: this.props.common.strings['page.my_lists.list.btn.remove'],
        callback: () => {
          this.props.actions.user
            .deleteListItem(data.listId, [data.itemId])
            .then(() => {
              this.props.actions.user.getListItems({
                list_id: list_id,
                page: 1,
                per_page: PER_PAGE,
                ...this.props.common.searchParams,
              })
            })
        },
      })
    }
  }

  get buttons() {
    if (!this.props.canCopyLink) {
      return this.props.buttons.concat({
        text: this.props.common.strings['copy_link'],
        callback: () => {
          copy(
            `${document.location.protocol}//${
              document.location.host + (this.props.link || '')
            }`
          )
          this.props.actions.notifications.createNotification({
            type: 'notify',
            children: this.props.common.strings['after_copy_link'],
          })
        },
      })
    }

    return this.props.buttons.concat({
      text: this.props.common.strings['copy_link'],
      callback: () => {
        copy(
          `${document.location.protocol}//${
            document.location.host + (this.props.link || '')
          }`
        )
        this.props.actions.notifications.createNotification({
          type: 'notify',
          children: this.props.common.strings['after_copy_link'],
        })
      },
    })
  }

  get addToListButton() {
    if (this.props.addToListButton && this.props.user.isSignedIn) {
      const myLists = this.props.user.myVideoLists.payload.lists
      const modList = typeof myLists !== 'undefined' ? myLists.slice() : []

      if (
        this.props.user.myVideoLists.pending &&
        (!myLists || myLists.length === 0)
      ) {
        modList.push({
          title: 'Loading ...',
          onClick: null,
          slug: '',
          id: '',
        })
      }

      modList.push({
        title: this.props.common.strings['page.my_lists.add_list_new'],
        onClick: this.props.actions.common.openNewListModal,
        slug: '',
        id: '',
      })

      const { pending, payload } = this.props.user.myVideoLists
      const listsWithVideo = pending ? [] : payload.lists_with_video

      return this.props.addToListButton.concat({
        text: this.props.common.strings['page.my_lists.add_list'],
        items: modList,
        listsWithVideo: listsWithVideo,
        onChange: this.selectList,
        onOpen: this.openList,
      })
    }
  }

  openList = () => {
    const videoID = this.props.videoId
    this.props.actions.user.getVideoLists({
      video_id: videoID,
    })
    this.props.setCurrentVideo &&
      this.props.setCurrentVideo(this.props.externalName)
  }

  selectList = (ev, list_id, item) => {
    const myList = this.props.user.myVideoLists.payload

    const currentList = myList.lists.find(l => l.slug == list_id)
    const inList = myList.lists_with_video.includes(currentList.id)

    if (inList) {
      this.props.actions.notifications.createNotification({
        type: 'notify',
        children: this.props.common.strings['page.my_lists.video_exists'],
      })
    } else {
      this.props.actions.user
        .addListItem('video_project', this.props.externalName, list_id)
        .then(
          () => {
            this.props.actions.user.getVideoLists({
              video_id: this.props.videoId,
            })
          },
          this.props.actions.notifications.createNotification({
            type: 'success',
            children:
              this.props.common.strings['page.profile.menu.after_update'],
          })
        )
    }
  }

  setHover = hover => ev => {
    this.setState({ hover })
  }

  className() {
    const viewType = this.props.common.listViewType
    const grid = viewType === 'grid'
    switch (this.props.view) {
      case 'ae':
        return 'ae-item'
      case 'lae':
        return 'lae-item'
      case 'dbl':
        return 'dashboard-live-item'
      case 'dbs':
        return 'dashboard-slider-item'

      case 'auto':
        return grid ? 'ae-item' : 'lae-item'
      default:
        return ''
    }
  }

  cmp() {
    const viewType = this.props.common.listViewType
    const grid = viewType === 'grid'
    switch (this.props.view) {
      case 'grid':
        return GCItem
      case 'list':
        return LCItem
      case 'dbl':
        return DBLItem
      case 'dbs':
        return DBSItem
      case 'auto':
        return grid ? GCItem : LCItem
      default:
        return grid ? GCItem : LCItem
    }
  }

  render() {
    let Cmp = this.cmp()
    const place = [
      this.props.itemRegion,
      this.props.itemCountry,
      this.props.itemLocation,
    ]
      .filter(Boolean)
      .join(', ')
    const {
      user: { availableContent: { payload: availableContent = {} } = {} },
    } = this.props
    const accessStatus = availableContent[this.props.aggregatedLimitType]
    const lockedForUser = typeof accessStatus === 'boolean' && !accessStatus

    const renderedComponent = (
      <Cmp
        {...this.props}
        duration={
          this.props.duration
            ? formatDuration(this.props.duration)
            : this.props.duration
        }
        place={place}
        buttons={this.buttons}
        lockedForUser={lockedForUser}
        removeButton={this.removeButton}
        hover={this.state.hover}
        strings={this.props.common.strings}
        onClick={this.props.onClick}
        addToListButton={this.addToListButton}
        isArchive={this.props.aggregatedLimitType === 'archive'}
        isPremiumArchive={this.props.aggregatedLimitType === 'premium_archive'}
        cuttingAvailable={this.props.cuttingAvailable}
      />
    )

    if (this.props.view) {
      return (
        <div
          className={Component.classList(
            this.className(),
            this.props.modificator
          )}
          onMouseEnter={this.setHover(true)}
          onMouseLeave={this.setHover(false)}
        >
          {renderedComponent}
        </div>
      )
    } else {
      return renderedComponent
    }
  }
}

export default DI(['notifications', 'user', 'common'])(withRouter(ListItem))
